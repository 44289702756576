<script setup>
import { inject, ref } from 'vue';
import { Dialog } from 'vant';
import Navigation from '@/components/Navigation';

const $require = inject('$require');
let { router } = inject('ctx');
// 跳转页面
function goUrl (url) {
  router.push(url);
}
let userInfo = ref(null);
userInfo.value = window.localStorage.getItem('propertyUserInfo')
  ? JSON.parse(window.localStorage.getItem('propertyUserInfo'))
  : {};
// 获取信息
let getUserInfo = () => {
  $require.HttpPost('/shop/info', {}, { isTokn: false, isURl: true }).then((res) => {
    window.localStorage.setItem('propertyUserInfo', JSON.stringify(res.data));
    userInfo.value = res.data;
  });
  userInfo.value.merchantsLogo = process.env.VUE_APP_IMG_URL + userInfo.value.merchantsLogo;
};
getUserInfo();
// 退出登录
let outLogin = () => {
  Dialog.confirm({
    title: '退出登录',
    message: '你是否确认退出登录',
    confirmButtonColor: '#85C226'
  }).then(() => {
    window.localStorage.setItem('propertyToken', '');
    window.localStorage.setItem('propertyUserInfo', '');
    router.replace({ path: '/login' });
  });
};
</script>
<template lang="html">
  <div class="my">
    <div class="user-box" @click="$router.push({ path: '/personal/index' })">
      <div>
        <div class="ava-box">
          <img class="avater" v-avatar :src="userInfo && userInfo.merchantsLogo" />
        </div>
        <div class="info">
          <div>{{ userInfo.merchantsName }}</div>
          <div>{{ userInfo.merchantsContact }}</div>
        </div>
      </div>
      <img class="right" src="../assets/image/my/y.png" alt />
    </div>
    <!-- item -->
    <div class="item-box">
      <div class="item" @click="goUrl('/account/balance')">
        <div>
          <img class="item-img" src="../assets/image/my/zhye.png" alt />
          <span>账户余额</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <!-- <div class="item" @click="goUrl(`/account/othFeePage`)">
        <div>
          <img class="item-img" src="../assets/image/my/bzj.png" alt />
          <span>其它费用</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div> -->
      <div class="item" @click="goUrl(`/contribute/record`)">
        <div>
          <img class="item-img" src="../assets/image/my/jfdetail.png" alt />
          <span>费用详情</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <!-- <div class="item" @click="goUrl('/account/earnmoney')">
        <div>
          <img class="item-img" src="../assets/image/my/yxjz.png" alt />
          <span>意向金转余额</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div> -->
      <div class="item" @click="goUrl('/store/index')">
        <div>
          <img class="item-img" src="../assets/image/my/spxx.png" alt />
          <span>商铺信息</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <div class="item" @click="goUrl('/account/contract')">
        <div>
          <img class="item-img" src="../assets/image/my/htxx.png" alt />
          <span>合同信息</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <div class="item" @click="goUrl('/waterElectricity')">
        <div>
          <img class="item-img" src="../assets/image/my/shudianbiao.png" alt />
          <span>智能水电表余额</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <div class="item" @click="goUrl('/account/index')">
        <div>
          <img class="item-img" src="../assets/image/my/zhaq.png" alt />
          <span>账号安全</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <div class="item" @click="goUrl('/about/index')">
        <div>
          <img class="item-img" src="../assets/image/my/gysc.png" alt />
          <span>关于市场</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
      <div class="item" @click="outLogin">
        <div>
          <img class="item-img" src="../assets/image/outLogin.png" alt />
          <span>退出登录</span>
        </div>
        <img class="right" src="../assets/image/my/y.png" alt />
      </div>
    </div>
    <Navigation :index="1"></Navigation>
  </div>
</template>
<style lang="scss" scoped>
.my {
  width: 100%;
  height: 100%;
  background: url('../assets/image/my/bg.png') no-repeat;
  background-size: 100% 320px;
  padding: 80px 24px 24px 24px;
  box-sizing: border-box;

  .user-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 44px 0 40px;
    box-sizing: border-box;

    &>div {
      display: flex;
      align-items: center;
    }

    .info {
      margin-left: 39px;

      div {
        &:nth-child(1) {
          font-size: 36px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
          margin-bottom: 28px;
        }

        &:nth-child(2) {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #b9b9b9;
        }
      }
    }

    .ava-box {
      clip-path: circle(40%);
      width: 200px;
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;

      .avater {
        width: 100%;
      }
    }

    .right {
      width: 13px;
      height: 22px;
    }
  }

  // item
  .item-box {
    width: 100%;
    height: 974px;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    margin-top: 60px;
    padding: 51px 39px 49px 41px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;

      .item-img {
        width: 48px;
        height: 48px;
        vertical-align: middle;
        margin-right: 41px;
      }

      .right {
        width: 13px;
        height: 22px;
      }
    }
  }
}
</style>
